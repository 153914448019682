/**
 * @author alteredq / http://alteredqualia.com/
 */

THREE.RenderPass = function (
  scene,
  camera,
  overrideMaterial,
  clearColor,
  clearAlpha
) {
  THREE.Pass.call(this);

  this.scene = scene;
  this.camera = camera;

  this.overrideMaterial = overrideMaterial;

  this.clearColor = clearColor;
  this.clearAlpha = clearAlpha !== undefined ? clearAlpha : 0;

  this.clear = true;
  this.clearDepth = false;
  this.needsSwap = false;
};

THREE.RenderPass.prototype = Object.assign(
  Object.create(THREE.Pass.prototype),
  {
    constructor: THREE.RenderPass,

    render: function (renderer, writeBuffer, readBuffer, delta, maskActive, object, camera, renderTarget, someBool, cameraData) {
      // var oldAutoClear = renderer.autoClear;
      // renderer.autoClear = true;

      // this.scene.overrideMaterial = this.overrideMaterial;

      // var oldClearColor, oldClearAlpha;

      // if ( this.clearColor ) {

      //   oldClearColor = renderer.getClearColor().getHex();
      //   oldClearAlpha = renderer.getClearAlpha();

      //   renderer.setClearColor( this.clearColor, this.clearAlpha );

      // }

      // if ( this.clearDepth ) {

      //   renderer.clearDepth();

      // }

      renderer.render(
        object,
        camera,
        readBuffer,
        true,
        cameraData
      );

      // if ( this.clearColor ) {

      //   renderer.setClearColor( oldClearColor, oldClearAlpha );

      // }

      // this.scene.overrideMaterial = null;
      // renderer.autoClear = oldAutoClear;
    },
  }
);
